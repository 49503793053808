@import '../../styles/settings.colors';
@import '../../styles/settings.font-size';
@import '../../styles/settings.media-queries';
@import '../../styles/settings.shadows';
@import '../../styles/settings.animation';
.svg {
  vertical-align: middle;
  display: inline;
  overflow: visible;
}

.small {
  width: 8px;
  height: 8px;
  stroke-width: 2px;
}

.medium {
  width: 20px;
  height: 20px;
  stroke-width: 0.8px;
}

.large {
  width: 24px;
  height: 24px;
  stroke-width: 0.66px;
}
