@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.container {
  flex: 1 0 auto;
  margin-top: 6rem;

  @include respond-below(sm) {
    display: flex;
    margin: 4rem 5rem;
  }
}

.content {
  position: relative;
  width: 100%;
  margin: 0 auto;
  background: transparent url('/static/images/notFound/404-pattern.svg')
    no-repeat;
  background-position: center;
  z-index: 3;

  @include respond-below(sm) {
    background: transparent
      url('/static/images/notFound/404-pattern-mobile.svg') no-repeat;
    background-position: top;
    svg {
      width: 4rem;
      height: 4rem;
    }
  }
}

body[data-theme='dark'] {
  .content {
    background: transparent url('/static/images/notFound/404-pattern-dark.svg')
      no-repeat;
    background-position: center;

    @include respond-below(sm) {
      background: transparent
        url('/static/images/notFound/404-pattern-mobile-dark.svg') no-repeat;
    }
    background-position: top;
  }
}

.centerAlign {
  text-align: center;
}

.content h1 {
  font-size: 2.4rem;
  line-height: 4rem;
  font-weight: bold;
  margin-top: 3.2rem;
  @include respond-below(xxs) {
    line-height: 3rem;
    padding: 0 7rem;
  }
  @include respond-below(sm) {
    margin: 4rem 0 1.6rem 0;
    font-size: 2rem;
  }
}

.content p {
  margin-bottom: 2.4rem;
  font-size: 1.4rem;
  line-height: 2rem;
  @include respond-below(xxs) {
    line-height: 3rem;
  }
  @include respond-below(sm) {
    margin-bottom: 4rem;
    font-size: $font-size-regular;
  }
}

.content .link {
  margin-top: 2.4rem;
}
