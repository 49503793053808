@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.tertiary {
  color: var(--kp-color-content-interactive-primary);
  border-radius: 0.2rem;
  padding: 0.8rem;

  &.small {
    padding: 0.2rem 0.4rem;
    height: 2.4rem;

    @include respond-below(sm) {
      padding: 0.4rem 0;
      height: auto;
    }

    > *:not(:last-child) {
      margin-right: 0.8rem;
    }
  }

  @include respond-below(sm) {
    font-weight: bold;
    &.big {
      padding: 1.2rem 0.8rem;
    }

    &.medium {
      padding: 0.8rem 0;
    }
  }
}

.tertiary:link,
.tertiary:visited {
  color: var(--kp-color-content-interactive-primary);
}

.tertiary:hover {
  background-color: var(--kp-color-fill-neutral-dim);

  @include respond-below(sm) {
    background-color: transparent;
  }
}

.tertiary:disabled,
.tertiary.isDisabled {
  background-color: transparent;
  color: var(--kp-color-disabled-dim);
}
